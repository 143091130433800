//scss 全局变量

$g-color-primary: #5066ee; //主颜色
$g-color-light-blue: #e2efff; //选中状态 ---- 浅蓝色
$g-color-border: #ecececff; //边框线  分割线
$g-color-error: #ff2d2d; //错误 重要提示
$g-color-warning: #fe802f; //警告
$g-color-green: #36b37e; //成功的绿
$g-color-white: #ffffff; //白色
$g-color-grey: #f4f6f8; //灰底按钮
$g-color-body-background: #fafafb; //全局背景色/hover状态下背景色/已选状态背景色

//文字颜色
$g-text-primary: #37383b; //主要的
$g-text-secondary: #b4bac6; //次要的
$g-text-placeholder: #b4bac6; //输入框提示

//导航栏、菜单栏
$g-nav-header-height: 70px; // 导航栏的高度
$g-home-menu-width: 240px; // 首页菜单宽度
$g-min-nav-header-width: 1200px; //

$g-main-max-width: 1920px; // 主体最大宽度
$g-main-min-width: 1440px; // 主体最小的宽度
$g-main-padding: 16px; // 主体的padding

// 隐藏默认滚动条
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

p {
  margin: 0;
  white-space: pre-wrap;
}

// 在谷歌下移除input[number]的上下箭头
.input-number::-webkit-outer-spin-button,
.input-number::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

// 同行
@mixin row {
  display: flex;
}

// 同行，逆序
@mixin row-reverse {
  @include row;
  flex-direction: row-reverse;
}

// 同行，间隔均匀
@mixin row-space-between {
  @include row;
  justify-content: space-between;
}

// 同行，间隔均匀，竖直居中
@mixin row-space-between-ver-center {
  @include row-space-between;
  align-items: center;
}

// 同行，水平居中
@mixin row-hor-center {
  @include row;
  justify-content: center;
}

// 同行，竖直居中
@mixin row-ver-center {
  @include row;
  align-items: center;
}

// 同行，上下左右居中
@mixin row-center {
  @include row-hor-center;
  @include row-ver-center;
}

// 同列
@mixin column {
  @include row;
  flex-direction: column;
}

// 同列，逆序
@mixin column-reverse {
  @include row;
  flex-direction: column-reverse;
}

// 同列，间隔均匀
@mixin column-space-between {
  @include row-space-between;
  @include column;
}

// 同列，水平居中
@mixin column-hor-center {
  @include column;
  align-items: center;
}

// 同列，竖直居中
@mixin column-ver-center {
  @include column;
  justify-content: center;
}

// 同列，上下左右居中
@mixin column-center {
  @include column-hor-center;
  @include column-ver-center;
}

// 同行
.row {
  @include row;
}

// 同行，逆序
.row-reverse {
  @include row-reverse;
}

// 同行，间隔均匀
.row-space-between {
  @include row-space-between;
}

// 同行，间隔均匀，竖直居中
.row-space-between-ver-center {
  @include row-space-between-ver-center;
}

// 同行，水平居中
.row-hor-center {
  @include row-hor-center;
}

// 同行，竖直居中
.row-ver-center {
  @include row-ver-center;
}

// 同行， 上下左右居中
.row-center {
  @include row-center;
}

// 同列
.column {
  @include column;
}

// 同列，逆序
.column-reverse {
  @include column-reverse;
}

// 同列，间隔均匀
.column-space-between {
  @include column-space-between;
}

// 同列，水平居中
.column-hor-center {
  @include column-hor-center;
}

// 同列，竖直居中
.column-ver-center {
  @include column-ver-center;
}

// 同列， 上下左右居中
.column-center {
  @include column-center;
}

// 文本溢出展示...，使用时需另外设置 -webkit-line-clamp控制展示行数
.text-ellipsis {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

// 单行文本，溢出展示...
.single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
}

// 文本不可选中
@mixin unselected {
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
}

// 文本不可选中
.unselected {
  @include unselected;
}

// 鼠标hover时变成手指形态
.pointer {
  cursor: pointer;
}

//scss 全局变量
$nav-header-height: 70px; // 导航栏的高度
$home-menu-width: 240px; // 首页菜单宽度
$min-nav-header-width: 1200px; //

$size-xs: 5px;
$size-sm: 10px;
$size-normal: 15px;
$size-lg: 20px;
$size-xl: 25px;
$size-xxl: 30px;
$size-xxxl: 35px;

// 主体最大宽度
$main-max-width: 1920px;
// 主体最小的宽度
$main-min-width: 1280px;
// 主体的水平padding
$main-padding-horizontal: 24px;
// 主体的垂直padding
$main-padding-vertical: 8px;
// 全局通用阴影
$global-box-shadow: 0px 0px 40px 0px rgba(168, 173, 198, 0.15);

$page-padding: calc((100vw - 1280px) / 2);
$page-padding-mobile: calc((100vw - 343px) / 2);

$text-color-hilight: #f0802f;

.ant-select-selection-overflow-item-rest {
  .ant-select-selection-item {
    background-color: transparent;
  }
}

// rem 适配
$design-width: 390;
@function toRem($px) {
  @return $px/$design-width * 10 + rem;
}

html {
  // 1rem
  font-size: 39px;
}

//滚动条样式
.g-scroll-bar {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(181, 181, 195, 0.3);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(181, 181, 195, 0.5);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
}

@mixin global-transition {
  transition: all 0.4s ease-in-out;
}

.message-center-wrapper-icon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  position: relative;
  background: url("@/assets/icon/message-center.png");
  background-size: 100% 100%;
  transition: background 0.3s;

  &:hover {
    transition: background 0.3s;
    background: url("@/assets/icon/message-center-hover.png");
    background-size: 100% 100%;
  }

  .message-center-wrapper-icon-length {
    position: absolute;
    font-size: 14px;
    background: rgba(245, 34, 45, 1);
    border-radius: 10px;
    height: 16px;
    display: flex;
    align-items: center;
    font-weight: 500;
    padding: 0 5px;
    color: rgba(255, 255, 255, 1);
    bottom: 15px;
    left: 14px;
  }
}

.notifications-drawer {
  &-content {
    .notifications-empty {
      display: flex;
      color: #63728b;
      font-size: 16px;
      line-height: 20px;
      padding: 130px 0;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        width: 40px;
        height: 40px;
        margin-top: 15px;
      }

      &.notifications-unread {
        height: calc(240px - 24px);
      }
    }

    .setting-box {
      width: 100%;
      position: absolute;
      top: 0;
      z-index: 1;
      height: calc(100vh - 73px);
      background: #fff;
      left: 556px;
      transition: left 0.2s ease-out;

      &.show {
        left: 0;
      }

      &-content {
        padding: 16px;
      }

      .react-nano-scrollbar-wrapper {
        height: 100%;
      }

      .ant-spin {
        min-height: 400px;
      }
    }
  }

  .ant-drawer-header {
    height: auto !important;

    .ant-drawer-title {
      padding-left: 16px;
      height: 24px;
    }

    .ant-drawer-close {
      top: 24px;
      right: 24px;
    }
  }

  .ant-drawer-body {
    padding: 0px;
  }

  .notifications-drawer-title {
    display: flex;
    width: 100%;

    svg {
      color: #b4bac6;
      transition: color 0.3s;

      &:hover {
        color: $g-color-primary;
      }
    }

    .title-text {
      font-size: 24px;

      & + .setting-title {
        position: absolute;
        top: 32px;
        left: 464px;
        cursor: pointer;
        transition: color 0.3s, left 0.2s ease-out;
        line-height: 1;
        color: #b4bac6;

        svg {
          margin-right: 0;
        }
      }
    }

    .setting-title {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #63728b;
      cursor: pointer;
      transition: color 0.3s, left 0.2s ease-out;
      position: absolute;
      top: 32px;
      left: 32px;

      &:hover {
        color: $g-color-primary;

        svg {
          color: $g-color-primary;
        }
      }

      > svg {
        margin-right: 9px;
      }
    }
  }
}

.notifications-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  .notifications-box-tab {
    display: flex;
    justify-content: space-between;
    padding: 0 32px;
    padding-top: 16px;
    padding-bottom: 16px;

    .notifications-box-tab-item {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #37383b;
    }

    .notifications-box-tab-item-handle {
      cursor: pointer;
      font-weight: 400;
      font-size: 16px;
      line-height: 18px;
      color: $g-color-primary;
    }
  }

  .notifications-box-center {
    flex: 1;
    padding: 16px;
    padding-top: 0;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      height: 8px;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(181, 181, 195, 0.3);
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgba(181, 181, 195, 0.5);
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
    }

    .notifications-item {
      margin-bottom: 24px;
      padding: 15px 16px;
      background: #f8f9fb;
      border-radius: 8px;
      display: flex;
      transition: all 0.3s;

      &.notifications-item-hiring {
        cursor: pointer;

        &:hover {
          background-color: #f0f1fd;
        }
      }

      .notifications-item-center {
        flex: 1;

        .notifications-item-center-text {
          font-size: 16px;
          line-height: 20px;
          color: #37383b;

          // word-break: break-all;
          .name {
            color: $g-color-primary;
          }

          &-span {
            span {
              font-style: oblique;
              font-weight: bold;
              padding: 0 2px;
            }
          }
        }

        .notifications-item-center-time {
          margin-top: 12px;
          font-size: 14px;
          line-height: 18px;
          color: #63728b;
        }
      }

      .notifications-item-icon {
        margin-left: 13px;

        .icon-check {
          color: #b4bac6;
          cursor: pointer;
          transition: color 0.3s;

          &:hover {
            color: $g-color-primary;
          }
        }
      }
    }

    .notifications-item-text {
      font-size: 18px;
      line-height: 22px;
      color: #37383b;
      margin-top: 8px;
      margin-bottom: 16px;
      padding: 0 16px;
      font-weight: bold;
    }

    .notifications-last {
      padding-left: 10px;
    }
  }

  .notifications-box-no-list {
    text-align: center;
    margin-top: 130px;

    .text-no-list {
      font-style: normal;
      font-size: 16px;
      line-height: 20px;
      color: #63728b;
      margin-bottom: 17px;
    }
  }
}

.setting-box {
  .setting-box-title {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    color: #37383b;
    padding: 0 32px;
    margin-bottom: 16px;
    margin-top: 8px;
  }

  .setting-box-item {
    display: flex;
    padding: 16px;
    background: #f8f9fb;
    border-radius: 8px;
    margin-bottom: 24px;

    .setting-box-item_subtitle {
      margin-top: 16px;
      margin-bottom: 16px;
      height: 18px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height, or 129% */
      color: #b4bac6;
      /* Inside auto layout */
      flex: none;
      order: 0;
      flex-grow: 0;
    }

    .setting-box-item-center {
      flex: 1;
      overflow: hidden;

      .ant-checkbox-inner {
        width: 20px;
        height: 20px;
        border-color: #b4bac6;
      }

      .text-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: #37383b;
      }

      .select-time-box {
        display: flex;
        margin-top: 22px;

        .select-time-box-item {
          margin-right: 8px;
          display: flex;
          align-items: center;

          .select-label {
            font-size: 14px;
            line-height: 18px;
            color: #37383b;
            margin-right: 8px;
          }
        }
      }

      .radio-group-set {
        margin-top: 16px;
        display: flex;

        .ant-radio-wrapper {
          &.ant-radio-wrapper-checked {
            .ant-radio-inner {
              border-color: #5066ee;
              background: #5066ee;
            }
          }
        }
      }
    }

    .setting-box-item-icon {
      flex: none;
    }
  }

  .g-select {
    &:not(.ant-select-customize-input) .ant-select-selector {
      background-color: #fff;
      border: 1px solid #f1f1f1;
    }
  }
}
