//scss 全局变量

$g-color-primary: #5066ee; //主颜色
$g-color-light-blue: #e2efff; //选中状态 ---- 浅蓝色
$g-color-border: #ecececff; //边框线  分割线
$g-color-error: #ff2d2d; //错误 重要提示
$g-color-warning: #fe802f; //警告
$g-color-green: #36b37e; //成功的绿
$g-color-white: #ffffff; //白色
$g-color-grey: #f4f6f8; //灰底按钮
$g-color-body-background: #fafafb; //全局背景色/hover状态下背景色/已选状态背景色

//文字颜色
$g-text-primary: #37383b; //主要的
$g-text-secondary: #b4bac6; //次要的
$g-text-placeholder: #b4bac6; //输入框提示

//导航栏、菜单栏
$g-nav-header-height: 70px; // 导航栏的高度
$g-home-menu-width: 240px; // 首页菜单宽度
$g-min-nav-header-width: 1200px; //

$g-main-max-width: 1920px; // 主体最大宽度
$g-main-min-width: 1440px; // 主体最小的宽度
$g-main-padding: 16px; // 主体的padding

// 隐藏默认滚动条
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

p {
  margin: 0;
  white-space: pre-wrap;
}

// 在谷歌下移除input[number]的上下箭头
.input-number::-webkit-outer-spin-button,
.input-number::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

// 同行
@mixin row {
  display: flex;
}

// 同行，逆序
@mixin row-reverse {
  @include row;
  flex-direction: row-reverse;
}

// 同行，间隔均匀
@mixin row-space-between {
  @include row;
  justify-content: space-between;
}

// 同行，间隔均匀，竖直居中
@mixin row-space-between-ver-center {
  @include row-space-between;
  align-items: center;
}

// 同行，水平居中
@mixin row-hor-center {
  @include row;
  justify-content: center;
}

// 同行，竖直居中
@mixin row-ver-center {
  @include row;
  align-items: center;
}

// 同行，上下左右居中
@mixin row-center {
  @include row-hor-center;
  @include row-ver-center;
}

// 同列
@mixin column {
  @include row;
  flex-direction: column;
}

// 同列，逆序
@mixin column-reverse {
  @include row;
  flex-direction: column-reverse;
}

// 同列，间隔均匀
@mixin column-space-between {
  @include row-space-between;
  @include column;
}

// 同列，水平居中
@mixin column-hor-center {
  @include column;
  align-items: center;
}

// 同列，竖直居中
@mixin column-ver-center {
  @include column;
  justify-content: center;
}

// 同列，上下左右居中
@mixin column-center {
  @include column-hor-center;
  @include column-ver-center;
}

// 同行
.row {
  @include row;
}

// 同行，逆序
.row-reverse {
  @include row-reverse;
}

// 同行，间隔均匀
.row-space-between {
  @include row-space-between;
}

// 同行，间隔均匀，竖直居中
.row-space-between-ver-center {
  @include row-space-between-ver-center;
}

// 同行，水平居中
.row-hor-center {
  @include row-hor-center;
}

// 同行，竖直居中
.row-ver-center {
  @include row-ver-center;
}

// 同行， 上下左右居中
.row-center {
  @include row-center;
}

// 同列
.column {
  @include column;
}

// 同列，逆序
.column-reverse {
  @include column-reverse;
}

// 同列，间隔均匀
.column-space-between {
  @include column-space-between;
}

// 同列，水平居中
.column-hor-center {
  @include column-hor-center;
}

// 同列，竖直居中
.column-ver-center {
  @include column-ver-center;
}

// 同列， 上下左右居中
.column-center {
  @include column-center;
}

// 文本溢出展示...，使用时需另外设置 -webkit-line-clamp控制展示行数
.text-ellipsis {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

// 单行文本，溢出展示...
.single-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-line-clamp: 1;
}

// 文本不可选中
@mixin unselected {
  -moz-user-select: none; /* Firefox私有属性 */
  -webkit-user-select: none; /* WebKit内核私有属性 */
  -ms-user-select: none; /* IE私有属性(IE10及以后) */
  -khtml-user-select: none; /* KHTML内核私有属性 */
  -o-user-select: none; /* Opera私有属性 */
  user-select: none; /* CSS3属性 */
}

// 文本不可选中
.unselected {
  @include unselected;
}

// 鼠标hover时变成手指形态
.pointer {
  cursor: pointer;
}

//scss 全局变量
$nav-header-height: 70px; // 导航栏的高度
$home-menu-width: 240px; // 首页菜单宽度
$min-nav-header-width: 1200px; //

$size-xs: 5px;
$size-sm: 10px;
$size-normal: 15px;
$size-lg: 20px;
$size-xl: 25px;
$size-xxl: 30px;
$size-xxxl: 35px;

// 主体最大宽度
$main-max-width: 1920px;
// 主体最小的宽度
$main-min-width: 1280px;
// 主体的水平padding
$main-padding-horizontal: 24px;
// 主体的垂直padding
$main-padding-vertical: 8px;
// 全局通用阴影
$global-box-shadow: 0px 0px 40px 0px rgba(168, 173, 198, 0.15);

$page-padding: calc((100vw - 1280px) / 2);
$page-padding-mobile: calc((100vw - 343px) / 2);

$text-color-hilight: #f0802f;

.ant-select-selection-overflow-item-rest {
  .ant-select-selection-item {
    background-color: transparent;
  }
}

// rem 适配
$design-width: 390;
@function toRem($px) {
  @return $px/$design-width * 10 + rem;
}

html {
  // 1rem
  font-size: 39px;
}

//滚动条样式
.g-scroll-bar {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(181, 181, 195, 0.3);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(181, 181, 195, 0.5);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
  }
}

@mixin global-transition {
  transition: all 0.4s ease-in-out;
}

/** icon区域 */
.custom-right-content {
  margin-right: 20px;
  display: flex;
  align-items: center;
  &[data-disabled="true"] {
    opacity: 0.2;
  }
  .virtual-office-icon {
    background: rgba(255, 95, 0, 0.05);
    border-radius: 6px;
    color: #37383b;
    padding: 6px 16px 6px 12px;
    cursor: pointer;
    margin-right: 12px;
    img {
      width: 21px;
      margin-right: 8px;
    }
    span {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .affair-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: url("@/assets/icon/affair-icon.png") no-repeat center center;
    background-size: 100% 100%;
    transition: background 0.3s;
    margin-right: 8px;
    background-size: 16px 16px;
    position: relative;
    &:hover {
      transition: background 0.3s;
      background: url("@/assets/icon/affair-icon-hover.png") no-repeat center center;
      background-size: 16px 16px;
    }

    .affair-wrapper-icon-length {
      position: absolute;
      font-size: 14px;
      background: rgba(245, 34, 45, 1);
      border-radius: 10px;
      height: 16px;
      display: flex;
      align-items: center;
      font-weight: 500;
      padding: 0 5px;
      color: rgba(255, 255, 255, 1);
      bottom: 15px;
      left: 14px;
    }
  }
  .dashboard-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: url("@/assets/icon/dashboard.png");
    background-size: 100% 100%;
    transition: background 0.3s;
    margin-right: 8px;
    position: relative;
    &:hover {
      transition: background 0.3s;
      background: url("@/assets/icon/dashboard-hover.png");
      background-size: 100% 100%;
    }
    &-wrapper-icon-length {
      position: absolute;
      font-size: 14px;
      background: rgba(245, 34, 45, 1);
      border-radius: 10px;
      height: 16px;
      display: flex;
      align-items: center;
      font-weight: 500;
      padding: 0 5px;
      color: rgba(255, 255, 255, 1);
      bottom: 15px;
      left: 14px;
    }
  }
  .bill-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    background: url("@/assets/icon/bill-icon.png");
    background-size: 100% 100%;
    transition: background 0.3s;
    margin-right: 8px;
    position: relative;
    &:hover {
      transition: background 0.3s;
      background: url("@/assets/icon/bill-icon-hover.png");
      background-size: 100% 100%;
    }
    &-wrapper-icon-length {
      position: absolute;
      font-size: 14px;
      background: rgba(245, 34, 45, 1);
      border-radius: 10px;
      height: 16px;
      display: flex;
      align-items: center;
      font-weight: 500;
      padding: 0 5px;
      color: rgba(255, 255, 255, 1);
      bottom: 15px;
      left: 14px;
    }
  }
  .message-center-wrapper-icon {
    cursor: pointer;
    width: 24px;
    height: 24px;
    position: relative;
    background: url("@/assets/icon/message-center.png");
    background-size: 100% 100%;
    transition: background 0.3s;
    &:hover {
      transition: background 0.3s;
      background: url("@/assets/icon/message-center-hover.png");
      background-size: 100% 100%;
    }
    .message-center-wrapper-icon-length {
      position: absolute;
      font-size: 14px;
      background: rgba(245, 34, 45, 1);
      border-radius: 10px;
      height: 16px;
      display: flex;
      align-items: center;
      font-weight: 500;
      padding: 0 4px;
      color: rgba(255, 255, 255, 1);
      bottom: 15px;
      left: 14px;
    }
  }
  .custom-right-content-online-layout {
    height: 36px;
    flex-shrink: 0;
    border-radius: 10px;
    background: var(--Background, #f8f9fb);
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
  }
  .interval {
    width: 1px;
    height: 12px;
    background: #e9e8e8;
  }
  .online-dot {
    width: 8px;
    height: 8px;
    flex-shrink: 0;
    fill: linear-gradient(180deg, #73ed54 0%, #3fd72a 100%);
  }
  .online-number {
    color: #444548;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    /* Body text 2 */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
  }
}

/* 修改密码弹窗区域 */
.avatar-dropdown-list-dialog {
  &.change-password-modal {
    .ant-drawer-footer {
      text-align: right;
    }
  }
  &.ant-drawer-footer {
  }
  .ant-modal-body {
    padding-bottom: 8px !important;
  }
  .get-code-item {
    .ant-input {
      border-right: 0;
      &[disabled] {
        background: #fafafb;
        color: #b4bac6;
      }
    }
    .ant-form-item-control-input {
      border: 1px solid #ececec;
      border-radius: 8px;
      overflow: hidden;
      transition: border-color 0.3s;
      &:hover {
        border-color: rgba(33, 84, 255, 1);
        transition: border-color 0.3s;
      }
    }
    &.get-code-item-focus {
      .ant-form-item-control-input {
        border-color: rgba(33, 84, 255, 1);
        transition: border-color 0.3s;
      }
    }
    .ant-input-group-addon {
      background: none;
      padding: 0;
      border: none;
      .get-code {
        width: auto;
        height: 20px;
        border: 0;
        border-left: 1px solid rgba(236, 236, 236, 1);
        padding: 0 12px 0 15px;
        font-size: 14px;
        color: rgba(33, 84, 255, 1);
        background: none;
        border-radius: 0;
        &:hover {
          background: none;
        }
      }
    }
  }
  .ant-col {
    &.ant-form-item-label {
      color: rgba(55, 56, 59, 1);
      line-height: 20px;
      padding-bottom: 12px;
    }
  }
  .ant-input {
    border-radius: 8px;
    &[type="number"] {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
      -moz-appearance: textfield;
    }
  }
}

/* 时区修改区域 */
.header-time-zone-drawer {
  .ant-drawer-body {
    padding: 0;
  }

  .react-nano-scrollbar-content-wrapper,
  .g-drawer-scroll-bar-wrapper {
    height: 100%;
  }

  .header-time-zone-drawer-wrapper {
    width: 100%;
    height: 100%;
    @include row;
    flex-direction: column;

    .header-time-zone-drawer-title {
      padding: 0 24px;
      margin-bottom: 24px;

      .title {
        font-weight: 800;
        font-size: 20px;
        color: rgba(55, 56, 59, 1);
        line-height: 28px;
        margin: 0;
      }

      h4 {
        color: rgba(155, 155, 155, 1);
        font-size: 14px;
        line-height: 22px;
        margin: 0;
        margin-top: 8px;
      }
      .time-zone-default {
        width: 100%;
        height: 42px;
        background: rgba(250, 250, 251, 1);
        border-radius: 8px;
        display: flex;
        margin-top: 16px;
        justify-content: space-between;
        align-items: center;
        padding: 0 18px;
        .ant-switch {
          width: 36px;
          height: 20px;
          &.ant-switch-checked {
            background-color: rgba(84, 202, 35, 1);
            .ant-switch-handle {
              left: calc(100% - 16px - 2px);
            }
          }

          .ant-switch-handle {
            height: 16px;
            width: 16px;
          }
        }
      }
    }

    .drawer-content {
      flex: 1;
      padding: 0 24px;
    }

    .header-time-zone-drawer-footer {
      width: 100%;
      height: 56px;
      display: flex;
      justify-content: flex-end;

      & > div {
        width: 600px;
        height: 56px;
        border-top: 1px solid rgba(246, 248, 250, 1);
        box-sizing: border-box;
        display: flex;
        padding-right: 20px;
        align-items: center;
        justify-content: flex-end;
        .ant-btn {
          //width: 74px;
          //height: 32px;
          //border-radius: 6px;
          //box-shadow: none;
        }
      }
    }
  }
}

/** 个人名片区域 */
.header-personal-settings {
  .ant-drawer-header {
    padding: 40px 32px 32px 32px;
  }
  .ant-drawer-body {
    padding-left: 32px;
    padding-right: 32px;
    padding-bottom: 0;
  }
  .header-personal-settings-content {
    margin-bottom: 59px;
    .header-personal-settings-content-list {
      margin-top: 21px;
      &.error {
        .header-personal-settings-content-list-phone {
          border-color: #ff7481;
          &:hover {
            border-color: #ff7481;
          }
        }
      }
      .header-personal-settings-content-list-phone-error-message {
        color: #b4bac6;
        margin-top: 4px;
      }
      .header-personal-settings-content-list-phone {
        display: inline-flex;
        align-items: center;
        width: 100%;
        background: #f8f9fb;
        border-radius: 4px;
        border: 1px solid #f8f9fb;
        position: relative;

        .ant-select-selector {
          border: none !important;
        }
        &:hover {
          border: 1px solid #7d92fa;
        }

        .ant-form-item {
          margin-bottom: 0;
          width: 100px;
          .ant-form-item-explain {
            display: none !important;
          }

          &:first-child {
            position: relative;
            .ant-select-arrow {
              right: 18px;
            }
            .ant-select-selection-item {
              color: #000;
            }
            &::after {
              content: "";
              display: inline-block;
              width: 1px;
              height: 21px;
              background: #b4bac6;
              position: absolute;
              right: 0;
              top: 0;
              bottom: 0;
              margin: auto;
              opacity: 0.3;
            }
          }
          &:last-child {
            flex: 1;
          }
        }
      }
      .label-text {
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #37383b;
        margin-bottom: 12px;
      }
      .center-text {
        font-size: 14px;
        color: #37383b;
        display: inline-block;
      }
      .change-password {
        display: inline-block;
        color: #5066ee;
        cursor: pointer;
        margin-left: 16px;
      }
      .label-text-inline {
        display: inline-block;
        margin-right: 24px;
      }
    }
    .list-label {
      display: flex;
      align-items: center;
      margin-top: 30px;
    }

    /*上传头像样式*/
    .header-personal-ava {
      display: flex;
      margin-bottom: 32px;
      .user-avatar-item {
        display: inline-block;
        width: 72px;
        margin-right: 16px;
        .row-ver-center {
          display: inline-block;
        }
      }
      .upload-wrapper {
        .top-box {
          .upload-btn {
            font-size: 16px;
          }
          .remove-span {
            margin-left: 26px;
            font-size: 16px;
            line-height: 20px;
            color: #5066ee;
            cursor: pointer;
          }
        }
        .upload-dec {
          margin-top: 12px;
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          color: #63728b;
        }
      }
    }
  }
}

.account-deactivation {
  margin-top: 31px;
  padding: 0px;
  position: absolute;
  height: 59px;
  line-height: 59px;
  bottom: 0px;
  background: #fff;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: space-between;
  &:before {
    content: "";
    display: block;
    position: fixed;
    width: 100%;
    height: 1px;
    background: #f1f1f1;
    margin-left: -32px;
  }
  .del-span {
    color: #b4bac6;
    cursor: pointer;
    &:hover {
      color: #5066ee;
    }
  }

  .terms-span {
    font-weight: 400;
    font-size: 14px;
    color: #63728b;
    a {
      color: #5066ee;
    }
  }
}

/** 企业信息区域 */
.enterprise-information {
  &-wrapper {
    .header-logo {
      margin-top: 0;
      font-size: 14px;
      line-height: 16px;
      color: rgba(155, 155, 155, 1);
      display: flex;
      align-items: center;

      .logo-item {
        display: inline-block;
        width: 64px;
        margin-right: 16px;
        text-align: right;
        .row-ver-center {
          display: inline-block;
        }
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
      .upload-wrapper {
        padding: 10px 4px;
        cursor: pointer;
        .upload-btn {
          display: flex;
          align-items: center;
          transition: color 0.3s;
          .upload-text {
            transition: color 0.3s;
          }
          &:hover {
            color: #2154ff;
            .upload-text {
              color: #2154ff;
            }
          }
          svg {
            margin-right: 4px;
          }
        }
      }
      .upload-text {
        font-weight: bold;
        color: #37383b;
        font-size: 16px;
      }

      .change-password {
        color: #2154ff;
        margin-left: 16px;
        cursor: pointer;
      }
      & > span {
        display: block;
        &:nth-child(1) {
          width: 72px;
          margin-right: 16px;
          text-align: right;
        }
        &:nth-child(2) {
          display: block;
          margin-left: 15px;
          line-height: 64px;
          font-size: 20px;
          color: rgba(55, 56, 59, 1);
          font-weight: 800;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &:nth-child(3) {
          color: rgba(33, 84, 255, 1);
          margin-left: 40px;
          cursor: pointer;
        }
      }
    }

    textarea {
      min-height: 80px !important;
    }

    .ant-drawer-body {
      padding: 32px;
      padding-top: 22px;
      .enterprise-information-header {
        display: flex;
        width: 100%;
        height: 86px;
        margin-bottom: 22px;
        & > div {
          height: 64px;
          display: flex;
          align-items: center;
          &:nth-child(1) {
            width: 64px;
            height: 64px;
            justify-content: center;
            overflow: hidden;
            flex-shrink: 0;
            border-radius: 50%;
            img {
              object-fit: cover;
            }
          }
          &:nth-child(2) {
            display: block;
            margin-left: 15px;
            line-height: 64px;
            font-size: 20px;
            color: rgba(55, 56, 59, 1);
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
      .enterprise-information-list {
        display: flex;
        width: 100%;
        padding-bottom: 8px;
        .enterprise-information-list-left {
          width: 70px;
          line-height: 22px;
          font-size: 14px;
          text-align: right;
          color: rgba(155, 155, 155, 1);
          flex-shrink: 0;
        }
        .enterprise-information-list-content {
          color: rgba(25, 28, 49, 1);
          line-height: 22px;
          font-size: 14px;
          margin-left: 4px;
          min-height: 100%;
          display: flex;
          align-items: center;
        }
      }
    }
  }
  &-inline {
    display: flex;
    .ant-form-item {
      flex: 1;
      &:nth-child(1) {
        margin-right: 16px;
      }
      .g-cascader {
        width: 100% !important;
      }
    }
  }
}

/** 消息中心 */
.message-center-wrapper {
  /* 头像hover区域 */
  .message-center-dropdown-up {
    position: absolute;
    width: 10px;
    height: 4px;
    background: url("@/assets/icon/dropdown-up.png");
    top: 8px;
    right: 7px;
  }
  .message-center-overlay {
    background: #fff;
    width: 400px;
    max-height: 510px;
    overflow: hidden;
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    margin-top: 12px;
    margin-right: -47px;
    .message-center-overlay-header {
      height: 63px;
      width: 100%;
      padding: 0 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(241, 241, 241, 1);
      color: rgba(55, 56, 59, 1);
      font-size: 14px;
      font-weight: 500;
      & > div:nth-child(2) {
        color: rgba(33, 84, 255, 1);
        cursor: pointer;
      }
    }
    .message-center-overlay-content {
      padding: 6px;
      .message-center-overlay-content-empty {
        display: flex;
        width: 100%;
        height: 124px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: rgba(155, 155, 155, 1);
        font-size: 14px;
        & > div:nth-child(1) {
          width: 39px;
          height: 47px;
          margin-bottom: 10px;
          background: url("@/assets/icon/notification-empty.png") no-repeat;
        }
      }
      .message-center-overlay-content-scroll-bar {
        width: 100%;
        max-height: 434px;
        overflow: hidden;
        .message-center-overlay-content-list {
          width: 388px;
          padding: 11px 18px;
          line-height: 20px;
          border-radius: 4px;
          color: rgba(25, 28, 49, 1);
          font-size: 14px;
          cursor: pointer;
          transition: all 0.3s;
          &:hover {
            background: rgba(250, 250, 251, 1);
            transition: all 0.3s;
          }
        }
      }
      &-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 32px;
        }
      }
    }
  }
}
